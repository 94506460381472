import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import SecondaryLanding from "../../../layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import Button from "../../../components/custom-widgets/button";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";

const CustomerEngagement = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/business-banking/business-services/customer-engagement/hero-customer-engagement-102623-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/business-banking/business-services/customer-engagement/hero-customer-engagement-102623-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/business-banking/business-services/customer-engagement/hero-customer-engagement-102623-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/business-banking/business-services/customer-engagement/hero-customer-engagement-102623-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/business-banking/business-services/customer-engagement/hero-customer-engagement-102623-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/business-banking/business-services/customer-engagement/hero-customer-engagement-102623-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/business-banking/business-services/customer-engagement/hero-customer-engagement-102623-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
      giftCardsImage: file(
        relativePath: { eq: "business-banking/business-services/thumbnail-gift-card-rewards-050824.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      analyticsImage: file(relativePath: { eq: "business-banking/business-services/thumbnail-analytics-050824.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      emailMarketingImage: file(
        relativePath: { eq: "business-banking/business-services/thumbnail-email-marketing-050824.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      customerIntelligenceImage: file(
        relativePath: { eq: "business-banking/business-services/thumbnail-customer-intelligence-050824.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const heroChevronData = {
    id: "customer-engagement-hero",
    ...getHeroImgVariables(imgData),
    altText: "Man walking through a shopping mall with bags.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Keep Customers Coming Back for More"
          },
          button: {
            id: "customer-engagement-hero-cta",
            text: "Get Started Today",
            url: "https://go.heartland.us/wafd#form",
            externalLink: true,
            class: "btn-white",
            containerClass: "btn-container-long",
            target: "blank"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking/small-business",
      title: "Business Banking"
    },
    {
      id: 2,
      url: "/business-banking/business-services",
      title: "Business Services"
    },
    {
      id: 3,
      active: true,
      title: "Customer Engagement"
    }
  ];

  const SEOData = {
    title: "Customer Engagement",
    meta: [
      {
        name: "title",
        property: "og:title",
        content: "Customer Engagement"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Looking for a customer loyalty program for your small business? Find the right program to fit your needs with WaFd Bank and Heartland Customer Engagement."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-services/customer-engagement"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-customer-engagement-102623.jpg"
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container" id="customer-engagement-section">
        <h1>Customer Engagement</h1>
        <h3>
          Get in-depth analytics on your customers and products to help make marketing and promotion decisions. Get
          access to a powerful suite to help create customer loyalty, including gift card and email marketing programs.
          Select the Payments option when requesting more information.
        </h3>
        <Button
          id="get-started-cta-1"
          text="Get Started"
          url="https://go.heartland.us/wafd#form"
          externalLink={true}
          containerClass="btn-container-long"
          target="blank"
          showIcon={false}
        />
      </section>
      <section className="bg-light" id="gift-cards-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <GatsbyImage
                id="gift-cards-image"
                image={imgData.giftCardsImage.childImageSharp.gatsbyImageData}
                alt="Woman laying on a couch while shopping with rewards card on her laptop. "
                className="mb-2 mb-md-0"
              />
            </div>
            <div className="col-12 col-md-6">
              <h2>Gift Cards and Rewards</h2>
              <ul id="gift-cards-list">
                <li id="gift-cards-list-item-1">Increase brand exposure to generate new customers and revenue</li>
                <li id="gift-cards-list-item-2">
                  Increase sales, promote your brand, introduce your business to potential customers and remind existing
                  ones what's great about your business
                </li>
                <li id="gift-cards-list-item-3">Engage customers to get people in the door and coming back</li>
                <li id="gift-cards-list-item-4">
                  Set apart your brand by customizing gift cards with your company's logo and colors
                </li>
                <li id="gift-cards-list-item-5">Redeem, reload, reuse on POS, kiosks and online</li>
                <li id="gift-cards-list-item-6">Lock in customer funds while also reducing payment processing fees</li>
              </ul>
              <Button
                id="get-started-cta-2"
                text="Get Started"
                url="https://go.heartland.us/wafd#form"
                externalLink={true}
                target="blank"
                showIcon={false}
                class="btn-link"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="container" id="analytics-section">
        <div className="row">
          <div className="col-12 col-md-6 order-2 order-md-1">
            <h2>Analytics</h2>
            <ul id="analytics-list">
              <li id="analytics-list-item-1">
                Validate your marketing and advertising effectiveness for optimum impact
              </li>
              <li id="analytics-list-item-2">
                Elevate card processing by harnessing the power of your card data to gain insight into customer visits,
                sales volume and average ticket metrics
              </li>
              <li id="analytics-list-item-3">
                Track marketing effectiveness to determine if campaigns are attracting new or repeat customers, and gain
                insight into customer purchasing habits to know if you're increasing average spend
              </li>
              <li id="analytics-list-item-4">
                Positively impact sales by spending your marketing and advertising dollars where they're most effective
              </li>
            </ul>
            <Button
              id="get-started-cta-3"
              text="Get Started"
              url="https://go.heartland.us/wafd#form"
              externalLink={true}
              target="blank"
              showIcon={false}
              class="btn-link"
            />
          </div>
          <div className="col-12 col-md-6 order-1 order-md-2">
            <GatsbyImage
              id="analytics-image"
              image={imgData.analyticsImage.childImageSharp.gatsbyImageData}
              alt="Coworkers reviewing analytics on a laptop in a cafe."
              className="mb-2 mb-md-0"
            />
          </div>
        </div>
      </section>
      <section className="bg-light" id="email-marketing-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <GatsbyImage
                id="email-marketing-image"
                image={imgData.emailMarketingImage.childImageSharp.gatsbyImageData}
                alt="Business owner working on a laptop in his cafe."
                className="mb-2 mb-md-0"
              />
            </div>
            <div className="col-12 col-md-6">
              <h2>Email Marketing</h2>
              <ul id="email-marketing-list">
                <li id="email-marketing-list-item-1">
                  Simplify email marketing and track return on investment to drive more customers to your business
                </li>
                <li id="email-marketing-list-item-2">
                  Quickly create email campaigns to promote new items, special deals and events with customizable
                  templates and drag-and-drop editing
                </li>
                <li id="email-marketing-list-item-3">
                  Validate campaign effectiveness by tracking how campaigns are impacting new and repeat business to
                  increase sales volume
                </li>
                <li id="email-marketing-list-item-4">
                  Optimize results by targeting the right communication to the right customers, prioritizing the most
                  effective efforts, and quickly recreating them
                </li>
                <li id="email-marketing-list-item-5">
                  List management tools enable you to easily upload existing contacts or to create an online form to
                  gather customer contact information
                </li>
                <li id="email-marketing-list-item-6">
                  Part of our customer intelligence suite (select the Payments option when requesting more information)
                  which includes additional tools to optimize your business efforts.
                </li>
              </ul>
              <Button
                id="get-started-cta-4"
                text="Get Started"
                url="https://go.heartland.us/wafd#form"
                externalLink={true}
                target="blank"
                showIcon={false}
                class="btn-link"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="container" id="customer-intelligence-section">
        <div className="row">
          <div className="col-12 col-md-6 order-2 order-md-1">
            <h2>Customer Intelligence</h2>
            <ul id="customer-intelligence-list">
              <li id="customer-intelligence-list-item-1">Turn customer data into customer intelligence</li>
              <li id="customer-intelligence-list-item-2">
                Know your customers' spending habits, what they want and need, and monitor your competitors
              </li>
              <li id="customer-intelligence-list-item-3">
                Market smarter using advanced demographics to precisely target customers and fine tune messages
              </li>
              <li id="customer-intelligence-list-item-4">
                Respond to customers in real-time with social post visibility and an advanced response library of
                suggested social replies
              </li>
              <li id="customer-intelligence-list-item-5">
                Stay ahead of the competition with access to data that compares your revenue, transactions and average
                purchase price to similar businesses nearby
              </li>
            </ul>
            <Button
              id="get-started-cta-5"
              text="Get Started"
              url="https://go.heartland.us/wafd#form"
              externalLink={true}
              target="blank"
              showIcon={false}
              class="btn-link"
            />
          </div>
          <div className="col-12 col-md-6 order-1 order-md-2">
            <GatsbyImage
              id="customer-intelligence-image"
              image={imgData.customerIntelligenceImage.childImageSharp.gatsbyImageData}
              alt="Woman laughing in clothing shop with laptop on counter."
              className="mb-2 mb-md-0"
            />
          </div>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default CustomerEngagement;
